const outerWrapper = {
  display: "grid",
  bg: "white",
  p: [4],
  gridRow: ["2/3"],
  justifyContent: "end",
  alignContent: "center"
};

export { outerWrapper };
