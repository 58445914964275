import BWA from "../../../../data/assets/BWA_in_jar.inline.svg";
import { Agenda, Cover, Table, List, SectionCover, BackCover } from "@offcourse/presentation-theme/src/components";
import { DisplayText } from "@offcourse/atoms";
import React from 'react';
export default {
  BWA,
  Agenda,
  Cover,
  Table,
  List,
  SectionCover,
  BackCover,
  DisplayText,
  React
};