import { SxStyleProp } from "theme-ui";

export const labelStyles: SxStyleProp = {
  fontFamily: "heading",
  fontSize: 1,
  lineHeight: 1,
  pt: 0,
  userSelect: "none"
};

