// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---themes-homepage-theme-src-templates-home-page-index-tsx": () => import("./../../../../themes/homepage-theme/src/templates/HomePage/index.tsx" /* webpackChunkName: "component---themes-homepage-theme-src-templates-home-page-index-tsx" */),
  "component---themes-blog-theme-src-templates-post-layout-index-tsx": () => import("./../../../../themes/blog-theme/src/templates/PostLayout/index.tsx" /* webpackChunkName: "component---themes-blog-theme-src-templates-post-layout-index-tsx" */),
  "component---themes-blog-theme-src-templates-posts-layout-index-tsx": () => import("./../../../../themes/blog-theme/src/templates/PostsLayout/index.tsx" /* webpackChunkName: "component---themes-blog-theme-src-templates-posts-layout-index-tsx" */),
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js": () => import("./../../../../node_modules/gatsby-theme-mdx-deck/src/templates/deck.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js" */),
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-decks-js": () => import("./../../../../node_modules/gatsby-theme-mdx-deck/src/templates/decks.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-decks-js" */)
}

